<template>
  <form
    v-shortkey="shortkeys"
    @shortkey="triggerShortkey"
    v-if="!isLoading"
    @submit.prevent="handleSubmit"
    class="container"
  >
    <div class="row">
      <h4 class="col">Insurance</h4>
      <icon-button
        class="col-2 btn btn-outline-primary"
        type="button"
        @click="loadDefaults(caseDetails, insurance)"
        icon="undo"
        v-tooltip="'Revert to patient data.'"
      />
    </div>
    <div class="row mb-2 align-items-end">
      <insurance-input
        label="Insurance"
        name="insurance"
        id="insurance"
        ref="insuranceInput"
        accessKey="i"
        class="col"
        v-model="insurance.insuranceId"
        @closed="shiftFocus"
      />
    </div>
    <div class="row mb-2 align-items-end">
      <select-input
        label="Insurance Type"
        name="insuranceType"
        class="col-4"
        ref="insuranceType"
        v-model="insurance.insuranceType"
        placeholder="Select an insurance type."
        displayExpr="displayName"
        :items="insuranceTypes"
      />
      <select-input
        v-if="showBilling"
        label="Billing Cycle"
        name="billingCycle"
        class="col-4"
        v-model="billingCycle"
        :items="billingCycles"
      />
      <select-input
        v-if="showBilling"
        label="Billing Type"
        name="billingType"
        class="col-4"
        v-model="billingType"
        :items="billingTypes"
      />
    </div>
    <div class="row mb-2">
      <text-input
        label="Policy Number"
        name="policyNumber"
        class="col-4"
        v-model="insurance.policyNumber"
        data-private="redact"
      />
      <text-input
        name="groupNumber"
        label="Group Number"
        class="col-4"
        v-model="insurance.groupNumber"
        data-private="redact"
      />
      <text-input
        name="priorAuthorization"
        label="Prior Authorization"
        class="col"
        v-model="insurance.priorAuthorization"
      />
    </div>
    <div class="row mb-2">
      <select-input
        label="Relationship"
        class="col-4"
        v-model="insurance.insuranceRelationship"
        :items="relationships"
        displayExpr="name"
        ref="relationship"
        name="relationship"
        accessKey="e"
        id="relationship"
      />
      <text-input
        label="Guarantor Last Name"
        class="col-4"
        name="guarantor"
        v-model="guarantorLastName"
        :validator="$v.guarantorLastName"
        data-private="redact"
      />
      <text-input
        label="First Name"
        class="col-4"
        name="guarantor"
        v-model="guarantorFirstName"
        :validator="$v.guarantorFirstName"
        data-private="redact"
      />
    </div>
    <div class="row mb-2">
      <select-input
        label="Sex"
        class="col"
        name="sex"
        v-model="insurance.sex"
        :validator="$v.insurance.sex"
        :items="sexes"
      />

      <date-picker
        label="Date of Birth"
        class="col"
        ref="dataOfBirth"
        accessKey="d"
        name="dateOfBirth"
        :validator="$v.insurance.dateOfBirth"
        v-model="insurance.dateOfBirth"
        data-private="redact"
      />
    </div>
    <div class="row align-items-start">
      <text-input
        class="col-6"
        name="addressLine1"
        :validator="$v.insurance.addressLine1"
        v-model="insurance.addressLine1"
        label="Address Line 1"
        data-private="redact"
      />
      <text-input
        class="col-6"
        name="addressLine2"
        v-model="insurance.addressLine2"
        label="Address Line 2"
        data-private="redact"
      />
    </div>
    <div class="row">
      <address-lookup class="col" v-model="insurance" :validatorProp="validatorProp" />
    </div>
    <div class="row px-3 w-75">
      <Phones v-model="phoneNumbers" />
    </div>

    <div class="row justify-content-end mx-2">
      <button @click="$emit('close')" class="btn btn-danger" type="button">Cancel</button>
      <button :disabled="!permissions.CaseInsuranceView" class="btn btn-primary mx-2" type="submit">
        Save
      </button>
    </div>
  </form>
  <loader class="m-auto" v-else></loader>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { format, isValid } from "date-fns";
import InsuranceAPI from "@/services/insurance";
import { required } from "vuelidate/lib/validators";
import InsuranceInput from "../Selectors/Insurance.vue";
import { createLogComment, createLogItem, getAltKeys } from "../../../modules/helpers";
import auditLog from "../../../services/AuditLog";
import { cloneDeep } from "lodash";
import AddressLookup from "@/components/common/AddressLookup.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import TextInput from "@/components/common/TextInput.vue";
import Loader from "@/components/common/Loader";
import DatePicker from "@/components/common/DatePicker.vue";
import IconButton from "@/components/common/IconButton.vue";
import DropdownService from "@/services/dropdown.js";
import { CaseEditTypeEnum, CaseStatusEnum } from "@/modules/enums";
import moment from "moment";
import Phones from "../Phones.vue";
import { startCase } from "lodash";

export default {
  components: {
    InsuranceInput,
    AddressLookup,
    TextInput,
    DatePicker,
    SelectInput,
    Loader,
    IconButton,
    Phones
  },
  props: {
    value: {
      required: true
    },
    isEditing: {
      type: Boolean,
      default() {
        return false;
      }
    },
    isAdding: {
      type: Boolean,
      default() {
        return true;
      }
    },
    currentTab: {
      type: Number,
      default() {
        return 0;
      }
    },
    caseId: {
      default() {
        return this.$route.params.caseId;
      }
    },
    policies: {
      type: Array
    }
  },

  data() {
    return {
      shortkeys: getAltKeys("deis"),
      typeMap: [1, 2, 3],
      originalInsurance: {},
      isLoading: false,
      defaultInsurance: {
        caseId: 0,
        insuranceRelationship: null,
        guarantor: "",
        policyNumber: "",
        status: true,
        groupNumber: "",
        dateOfBirth: "",
        sex: null,
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: null,
        zipCode: "",
        priorAuthorization: ""
      },
      insurance: {
        caseId: 0,
        insuranceRelationship: null,
        guarantor: "",
        policyNumber: "",
        status: true,
        groupNumber: "",
        dateOfBirth: "",
        sex: null,
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: null,
        zipCode: "",
        priorAuthorization: ""
      },
      insuranceTypes: [
        {
          displayName: "Primary",
          id: 1
        },
        { displayName: "Secondary", id: 2 },
        { displayName: "Tertiary", id: 3 }
      ],
      relationships: [
        { name: "Self", id: 1 },
        { name: "Spouse", id: 2 },
        { name: "Child", id: 3 },
        { name: "Other", id: 4 }
      ],
      validatorProp: {
        state: {
          required
        },
        city: { required },
        zipCode: { required }
      },
      guarantorFirstName: null,
      guarantorLastName: null,
      billingCycle: null,
      billingType: null,
      billingCycles: [],
      phoneNumbers: [],
      originalDemoInformation: {}
    };
  },

  validations() {
    return {
      insurance: {
        insuranceId: {
          required
        },
        state: {
          required
        },
        sex: {
          required
        },
        addressLine1: { required },

        city: { required },
        zipCode: { required },
        dateOfBirth: {
          required
        }
      },
      guarantorFirstName: {
        required
      },
      guarantorLastName: {
        required
      }
    };
  },
  created() {
    let { caseId } = this.$route.params;
    if (this.caseId != caseId) {
      caseId = Number(this.caseId);
    }
    if (this.isEditing && this.value.id) {
      this.isLoading = true;
      this.insurance = { ...this.value };
      this.originalInsurance = cloneDeep({
        ...this.value,
        dateOfBirth: this.value.dateOfBirth
          ? new Date(this.value.dateOfBirth)
          : this.value.dateOfBirth
      });
      const regEx = /,/.test(this.insurance.guarantor)
        ? /(?<lastName>.+), (?<firstName>.+)/i.exec(this.insurance.guarantor)
        : /(?<firstName>.+) (?<lastName>.+)/i.exec(this.insurance.guarantor);
      this.guarantorLastName = regEx?.groups?.lastName || "";
      this.guarantorFirstName = regEx?.groups?.firstName || "";
    } else {
      this.insurance = {
        ...this.defaultInsurance,
        caseId: this.caseId
      };
      this.originalInsurance = cloneDeep({ ...this.insurance });
      this.isLoading = true;
    }
    this.isLoading = false;
    this.$store.dispatch("dropdowns/getSexes");
    this.$store.dispatch("dropdowns/getBillingTypes");
  },

  mounted() {
    const { caseId } = this.$route.params;
    if (parseInt(caseId) === parseInt(this.caseId) && !this.insurance.id) {
      this.loadDefaults(this.caseDetails, this.insurance);
    }
    if (!this.insurance.insuranceId) {
      this.$refs.insuranceInput.focus();
    }
    if (this.showBilling) {
      this.billingCycle = this.caseDetails.billingCycle;
      this.billingType = this.caseDetails.billingType;
      DropdownService.getBillingCycles().then(res => {
        this.billingCycles = res;
      });
    }
    this.phoneNumbers = cloneDeep(this.caseDetails.phoneNumbers);
    this.loadPatientDemo();
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      currentUser: state => state.currentUser,
      labSettings: state => state.labSettings,
      caseDetails: state => state.accessionStore.caseDetails,
      states: state => state.dropdowns.states,
      sexes: state => state.dropdowns.sexes,
      billingTypes: state => state.dropdowns.billingTypes
    }),
    ...mapGetters(["permissions"]),

    insuranceSearch() {
      return InsuranceAPI.searchStore;
    },
    guarantorFullName() {
      return this.guarantorLastName + ", " + this.guarantorFirstName;
    },
    showBilling() {
      return this.labSettings.ShowBillingOnCaseInsuranceForm;
    }
  },
  methods: {
    shiftFocus() {
      this.$refs.insuranceType.focus();
    },
    triggerShortkey(event) {
      switch (event.srcKey) {
        case "i":
          this.$refs.insuranceInput.focus();
          break;
        case "e":
          this.$refs.relationship.focus();
          break;
        case "d":
          this.$refs.dataOfBirth.focus();
          break;
        case "s":
          this.handleSubmit();
          break;
        default:
          break;
      }
    },
    calculateUnits() {
      this.units = this.transaction?.specimens?.reduce((acc, curr) => {
        return acc + (Number(curr?.quantity) || 0);
      }, 0);
    },
    loadDefaults(caseDetails, insurance) {
      const { caseId, patientLastName, patientFirstName, patientSex, patientDOB, addresses } =
        caseDetails;

      this.guarantorFirstName = patientFirstName;
      this.guarantorLastName = patientLastName;
      insurance.caseId = caseId;
      insurance.insuranceRelationship = 1;
      if (!insurance.id) {
        insurance.insuranceType = this.policies.length + 1;
      }

      insurance.sex = patientSex;
      insurance.dateOfBirth = patientDOB ?? "";
      if (addresses && addresses?.length) {
        const { line1, line2, zipCode, city, state } = addresses[0];
        insurance.addressLine1 = line1;
        insurance.addressLine2 = line2;
        insurance.zipCode = zipCode;
        insurance.city = city;
        insurance.state = state;
      } else {
        window.notify("No addresses found on patient record.", "warning");
      }
    },
    async handleSubmit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        await window.notify("Please verify your input.", "warning");
        return;
      }
      const submissionCall = async response => {
        this.isLoading = false;
        const logItem = createLogItem(this.caseDetails, 1);
        if (this.isEditing) {
          logItem.comments = createLogComment(
            { ...this.originalInsurance, dateOfBirth: null },
            { ...insurance, dateOfBirth: null }
          );
          logItem.comments = `${this.originalInsurance.id}: ${logItem.comments}`;
        }
        auditLog.insertLogMessage(logItem);
        const { validationErrors } = response;
        if (!validationErrors?.length) {
          this.$emit("submit", { ...response[0], isNew: Boolean(this.insurance.id) });
          const shouldUpdateBilling =
            this.showBilling &&
            (this.billingCycle !== this.caseDetails.billingCycle ||
              this.billingType !== this.caseDetails.billingType);
          this.phoneNumbers = this.phoneNumbers.filter(e => e?.phoneNumber);
          const shouldUpdatePhoneNumbers =
            JSON.stringify(this.phoneNumbers) !== JSON.stringify(this.caseDetails.phoneNumbers);
          if (shouldUpdateBilling || shouldUpdatePhoneNumbers) {
            await this.updateCaseInformation(shouldUpdateBilling);
          }
          if (
            this.labSettings.UpdateDemoFromSelfInsurance &&
            response[0].insuranceRelationship === 1
          ) {
            this.updateDemo();
          }
          return null;
        } else {
          this.isLoading = false;
          const message = validationErrors.join("\n");
          setTimeout(() => alert(message), 1);
        }
      };
      const cancel = () => {
        this.isLoading = false;
        setTimeout(() => alert("Server error, please verify your input and try again."), 1);
      };
      const { insurance } = this;
      insurance.guarantor = this.guarantorFullName;
      this.isLoading = true;
      let toIndex = insurance.insuranceType - 1;
      let fromIndex;
      if (!insurance.id) {
        fromIndex = this.policies.length;
      } else {
        fromIndex = this.policies.map(e => e.id).indexOf(insurance.id);
      }
      const currentInsuranceWithType = this.policies.find(
        e => e.insuranceType === insurance.insuranceType
      );
      if (currentInsuranceWithType?.id !== insurance.id) {
        const newPolicies = [...this.policies];
        newPolicies.splice(fromIndex, 1);
        newPolicies.splice(toIndex, 0, insurance);
        for (const idx in newPolicies) {
          const policy = newPolicies[idx];
          if (policy.id) {
            policy.insuranceType = this.typeMap[idx];
            await InsuranceAPI.updatePolicy(policy);
          }
        }
      }
      if (this.isEditing) {
        return InsuranceAPI.updatePolicy(insurance).then(submissionCall).catch(cancel);
      }
      return InsuranceAPI.addPolicy({ ...insurance })
        .then(submissionCall)
        .catch(cancel);
    },

    setInsurance(data) {
      if (data) {
        const { id } = data;
        this.insurance.insuranceId = id;
      }
    },
    formatDate(data) {
      if (!data) {
        return "";
      }
      if (data instanceof Date) {
        return format(data, "MM/dd/yyy hh:mm a");
      } else if (isValid(new Date(data))) {
        return format(new Date(data), "MM/dd/yyy hh:mm a");
      }
    },
    formatCurrency(number) {
      if (isNaN(number)) {
        return number;
      }
      const currency = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(Number(number));
      return currency;
    },
    updateCaseInformation(includesBilling) {
      if (
        [
          CaseStatusEnum.ReportedPrelim,
          CaseStatusEnum.Reported,
          CaseStatusEnum.ReReleased
        ].includes(this.caseDetails.status)
      ) {
        const changed = [];
        if (this.billingCycle !== this.caseDetails.billingCycle) {
          changed.push("Billing Cycle");
        }
        if (this.billingType !== this.caseDetails.billingType) {
          changed.push("Billing Type");
        }
        if (JSON.stringify(this.phoneNumbers) !== JSON.stringify(this.caseDetails.phoneNumbers)) {
          changed.push("Phone Numbers");
        }
        const reasonForChange = `Updated ${changed.join(" and ")} - ${moment().format(
          "M/D/YYYY h:mm A"
        )}`;
        this.$store.dispatch("accessionStore/ammendCase", {
          editType:
            includesBilling && this.labSettings.AllowCaseEditTypeBilling
              ? CaseEditTypeEnum.Billing
              : CaseEditTypeEnum.NonDiagnostic,
          reasonForChange: reasonForChange
        });
      }
      return this.$store.dispatch("accessionStore/updateCaseDetails", {
        ...this.caseDetails,
        billingCycle: this.billingCycle,
        billingType: this.billingType,
        phoneNumbers: this.phoneNumbers
      });
    },
    loadPatientDemo() {
      const { patientLastName, patientFirstName, patientSex, patientDOB, addresses } =
        this.caseDetails;
      if (addresses && addresses?.length) {
        const { line1, line2, zipCode, city, state } = addresses[0];
        this.originalDemoInformation = {
          ...this.originalDemoInformation,
          addressLine1: line1,
          addressLine2: line2,
          zipCode,
          city,
          state
        };
      } else {
        for (const field of ["addressLine1", "addressLine2", "zipCode", "city", "state"]) {
          this.originalDemoInformation[field] = null;
        }
      }
      this.originalDemoInformation = {
        ...this.originalDemoInformation,
        guarantorFirstName: patientFirstName,
        guarantorLastName: patientLastName,
        sex: patientSex,
        dateOfBirth: patientDOB
      };
    },
    async updateDemo() {
      const { states } = this;
      function getFieldDisplay(value, toUpperCase, fieldName) {
        if (typeof value === "string") {
          const dateRegex = /^\d{4}-\d{2}-\d{2}/;
          if (dateRegex.test(value)) {
            const date = dateRegex.exec(value);
            value = moment(date[0]).format("M/D/YYYY");
          }
          if (toUpperCase) {
            value = value.toUpperCase();
          }
        }
        if (typeof value === "number" && fieldName) {
          if (fieldName === "state") {
            const stateData = states.find(e => e.id === value);
            value = stateData.displayName;
          }
          if (fieldName === "sex") {
            const genders = ["M", "F", "O", "U"];
            value = genders[value];
          }
        }
        return value;
      }
      const changedFields = [];
      for (const field of Object.keys(this.originalDemoInformation)) {
        const value = field.includes("guarantor") ? this[field] : this.insurance[field];
        if (
          getFieldDisplay(value, true) !==
          getFieldDisplay(this.originalDemoInformation[field], true)
        ) {
          changedFields.push({ field, value });
        }
      }
      if (changedFields.length) {
        const paddingSyle = " style='padding:5px;''";
        const confirmText = `Some patient demographic information on the insurance is different from the patient demographic information on the case. Would you like to update the information for the case?<br><br><table><tr><th${paddingSyle}>Property</th><th${paddingSyle}>Case Value</th><th${paddingSyle}>Insurance Value</th></tr>${changedFields.map(
          e =>
            `<tr><td${paddingSyle}>${startCase(
              e.field.replace("guarantor", "")
            )}</td><td${paddingSyle}>${getFieldDisplay(
              this.originalDemoInformation[e.field],
              null,
              e.field
            )}</td><td${paddingSyle}>${getFieldDisplay(e.value, null, e.field)}</td></tr>`
        )}</table>`;
        const confirm = await window.confirm(confirmText);
        if (confirm) {
          const demoFieldMap = {
            guarantorFirstName: "patientFirstName",
            guarantorLastName: "patientLastName",
            sex: "patientSex",
            dateOfBirth: "patientDOB"
          };
          let updatePayload = cloneDeep(this.caseDetails);
          if (!updatePayload.addresses?.length) {
            updatePayload.addresses.push({
              city: "",
              international: "",
              isPrimary: true,
              line1: "",
              line2: "",
              state: null,
              zipCode: ""
            });
          }
          for (const { field, value } of changedFields) {
            if (Object.keys(demoFieldMap).includes(field)) {
              updatePayload[demoFieldMap[field]] = value;
            } else if (field.includes("Line")) {
              updatePayload.addresses[0][field.replace("addressL", "l")] = value;
            } else {
              updatePayload.addresses[0][field] = value;
            }
          }
          if (
            [
              CaseStatusEnum.ReportedPrelim,
              CaseStatusEnum.Reported,
              CaseStatusEnum.ReReleased
            ].includes(this.caseDetails.status)
          ) {
            const reasonForChange = `Updated ${changedFields
              .map(e => e.field)
              .join(", ")} - ${moment().format("M/D/YYYY h:mm A")}`;
            this.$store.dispatch("accessionStore/ammendCase", {
              editType: CaseEditTypeEnum.NonDiagnostic,
              reasonForChange: reasonForChange
            });
          }
          await this.$store.dispatch("accessionStore/updateCaseDetails", updatePayload);
          window.notify("Updated patient information.");
        }
      }
    }
  },
  watch: {
    "insurance.policyNumber": function (value) {
      const { ForceUpperCaseInsurance } = this.labSettings;
      if (ForceUpperCaseInsurance) {
        if (parseInt(ForceUpperCaseInsurance)) {
          this.insurance.policyNumber = value.toUpperCase();
        }
      }
    },
    "insurance.groupNumber": function (value) {
      const { ForceUpperCaseInsurance } = this.labSettings;
      if (ForceUpperCaseInsurance) {
        if (parseInt(ForceUpperCaseInsurance)) {
          this.insurance.groupNumber = value.toUpperCase();
        }
      }
    },
    guarantorLastName: function (value) {
      const { ForceUpperCaseInsurance } = this.labSettings;
      if (ForceUpperCaseInsurance) {
        if (parseInt(ForceUpperCaseInsurance)) {
          this.guarantorLastName = value.toUpperCase();
        }
      }
    },
    guarantorFirstName: function (value) {
      const { ForceUpperCaseInsurance } = this.labSettings;
      if (ForceUpperCaseInsurance) {
        if (parseInt(ForceUpperCaseInsurance)) {
          this.guarantorFirstName = value.toUpperCase();
        }
      }
    },
    guarantorFullName: function () {
      this.insurance.guarantor = this.guarantorFullName;
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 1000px;
}

.tx_wrapper {
  width: 80vw;
}

.row {
  align-items: center;
}
.small {
  font-size: 0.78rem;
}
::v-deep div.dx-revert-button.dx-button {
  display: none;
}
.code-description {
  font-size: 0.75rem;
}
</style>

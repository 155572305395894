<template>
  <div>
    <label
      v-if="!noLabel"
      :for="$attrs.name || $attrs.id"
      id="insuranceLabel"
      v-html="displayName(accessKey, 'Insurance')"
    />
    <DxDropDownBox
      :inputAttr="$attrs"
      v-model="gridBoxValue"
      :data-source="insurance"
      :search-enabled="false"
      searchModeOption="contains"
      value-expr="id"
      :display-expr="insuranceTag"
      :show-clear-button="false"
      @opened="handleFocus"
      @closed="handleClose"
      @initialized="initializeDropdown"
      id="insuranceInput"
      v-if="!isModalOpen"
      :showClearButton="true"
    >
      <template v-slot:content>
        <dx-grid-with-search
          :data-source="insurance"
          :selection="selection"
          :columns="gridColumns"
          :remote-operations="remoteOperations"
          :paging="paging"
          :cellPrepared="setFocus"
          :show-borders="true"
          :hover-state-enabled="true"
          :selected-row-keys="gridBoxValue"
          @initialized="initialize"
          @cell-click="handleCellClick"
          :column-auto-width="true"
          :loadPanel="loadPanel"
          :scrolling="scrolling"
          :filterRow="filterRow"
          :toolbar="toolbar"
          :columnChooser="true"
          :allow-column-reordering="true"
          :gridName="'insuranceSelectorGrid'"
          :searchPanel="{}"
          @content-ready="onContentReady"
        >
          >
          <template v-slot:actions="{ data }">
            <div class="text-center">
              <icon-button
                v-tooltip.left="'Edit'"
                role="button"
                @click.stop="editInsurance(data)"
                class="text-primary"
                icon="pen-alt"
                v-if="permissions.InsuranceCodeCreateEdit"
              />
            </div>
          </template>
          <template v-slot:extraActions>
            <add-button @click="createInsurance" v-if="permissions.InsuranceCodeCreateEdit" />
          </template>
        </dx-grid-with-search>
      </template>
    </DxDropDownBox>
    <Modal :status="isModalOpen" @close="toggleModal">
      <Insurance-Maintenance
        :insuranceId="targetId"
        @cancel="toggleModal"
        @submit="submitInsurance"
      />
    </Modal>
  </div>
</template>

<script>
import DxDropDownBox from "devextreme-vue/drop-down-box";
import Modal from "../../common/Modal";
import InsuranceMaintenance from "@/components/CodeMaintenance/Insurance/InsuranceMaintenanceForm";
import { InsuranceApi } from "@/services/index.js";
import { mapGetters, mapState } from "vuex";
import IconButton from "@/components/common/IconButton.vue";
import AddButton from "@/components/common/AddButton.vue";
import DxGridWithSearch from "@/components/common/DxGridWithSearch.vue";
import { dateRangeFilter } from "@/modules/helpers";
import DataSource from "devextreme/data/data_source";
export default {
  name: "InsuranceSelector",
  inheritAttrs: false,
  components: {
    DxDropDownBox,
    InsuranceMaintenance,
    Modal,
    IconButton,
    AddButton,
    DxGridWithSearch
  },
  props: {
    noPrimary: {
      type: Boolean,
      default: false
    },
    setPrimary: {
      type: Function
    },
    value: {
      default: () => ""
    },
    accessKey: String,
    noLabel: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      insurance: new DataSource({
        store: InsuranceApi.searchStore,
        filter: dateRangeFilter()
      }),
      component: {},
      status: false,
      filterRow: {
        visible: true
      },
      scrolling: {
        columnRenderingMode: "virtual",
        rowRenderingMode: "virtual",
        showScrollbar: "always",
        useNative: true
      },
      remoteOperations: {
        paging: true,
        sorting: true,
        filtering: true
      },
      targetId: null,
      isModalOpen: false,
      loadPanel: {
        enabled: true
      },
      paging: {
        enabled: true,
        pageSize: 10
      },
      selection: {
        showCheckBoxesMode: "always",
        mode: "single"
      },
      dropdownOptions: { delayRendering: false },
      query: "",
      grid: {},
      toolbar: {
        items: [{ location: "after", template: "extraActions" }]
      },
      focusSet: false
    };
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
  watch: {
    isModalOpen(nv) {
      if (!nv) {
        this.grid.refresh(true);
      }
    }
  },
  methods: {
    initializeDropdown({ component }) {
      this.component = component;
    },
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    focus() {
      this.component.focus();
      this.component.open();
    },
    displayName(key = "", name) {
      if (key) {
        const regex = new RegExp(key, "i");
        if (regex.test(name)) {
          const { index } = name.match(regex);
          return `<b>${name.slice(0, index)}<u>${name[index]}</u>${name.slice(index + 1)}</b>`;
        }
      }
      return `<b>${name || ""}</b>`;
    },
    handleSubmit() {
      this.grid.reload();
    },
    editInsurance(data) {
      this.targetId = data?.data?.id || data;
      this.toggleModal();
      this.component.close();
    },
    setFocus(event) {
      const { cellElement, rowType, column } = event;
      if (rowType === "filter" && column.allowSorting && !this.focusSet) {
        cellElement.setAttribute("id", "filter");
        const input = cellElement.querySelector("input");
        if (input) {
          input.focus();
          this.focusSet = true;
        }
      }
    },
    handleFocus() {
      setTimeout(() => {
        const cellElement = document.querySelector("#filter");
        if (cellElement) {
          const input = cellElement.querySelector("input");
          if (input) {
            input.focus();
          }
        }
      }, 0);
    },
    initialize(event) {
      this.grid = event.component;
    },
    selectTags(data) {
      if (data.value?.length < data.previousValue?.length) {
        this.$emit(
          "input",
          this.value.filter(code => {
            return data.value.includes(code.id);
          })
        );
      }
    },
    handleOpen() {
      this.status = true;
    },
    handleClose() {
      this.status = false;
      this.focusSet = false;
    },
    selectItems() {
      const selectedRow = this.grid?.getSelectedRowsData();
      if (selectedRow?.length) {
        this.$emit("input", selectedRow[0].id);
        this.component.close();
        this.$emit("closed");
      }
    },
    insuranceTag(data) {
      if (data) {
        const { code, description, aliasCode, city } = data;
        return `${code}${aliasCode ? "/" + aliasCode : ""} (${city}) - ${description}`;
      }
      return "";
    },
    createInsurance() {
      this.isModalOpen = true;
    },
    submitInsurance(data) {
      this.toggleModal();
      this.component.close();
      this.$emit("input", data[0].id);
      this.targetId = null;
    },
    handleKeydown(e) {
      if (e.key === "Enter") {
        e.preventDefault();
        const grid = this.grid;
        this.selectItems(grid);
      }
      if (["ArrowDown", "ArrowUp"].includes(e.key)) {
        const row = this.grid.getSelectedRowsData();
        if (!row.length) {
          this.grid.selectRowsByIndexes([0]);
        } else {
          const currentIndex = this.grid.getRowIndexByKey(row[0].id);
          let targetIndex = 0;
          if (e.key === "ArrowUp") {
            if (currentIndex === 0) {
              targetIndex = 0;
            } else {
              targetIndex = currentIndex - 1;
            }
          } else {
            targetIndex = currentIndex + 1;
          }

          this.grid.selectRowsByIndexes([targetIndex]);
        }
      }
    },
    onContentReady() {
      this.grid.selectRowsByIndexes([0]);
    },
    handleCellClick({ data }) {
      this.grid.selectRows([data.id]);
      this.selectItems();
    }
  },
  computed: {
    ...mapState({ states: state => state.dropdowns.states }),
    ...mapGetters(["permissions"]),
    gridColumns() {
      return [
        {
          type: "command",
          cellTemplate: "actions",
          caption: "Actions"
        },
        {
          dataField: "description",
          sortIndex: 0,
          sortOrder: "asc",
          dataType: "string"
        },
        {
          dataField: "addressLine1",
          caption: "Address"
        },
        {
          dataField: "addressLine2",
          caption: "Line 2"
        },
        {
          dataField: "city"
        },
        {
          dataField: "state",
          lookup: {
            dataSource: this.states,
            displayExpr: "displayName",
            valueExpr: "id"
          }
        },
        {
          dataField: "zipCode",
          caption: "Zip"
        },
        {
          dataField: "code",
          caption: "Code"
        }
      ];
    },
    gridBoxValue: {
      get() {
        if (this.value) {
          return [this.value];
        }
        return [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom__tag {
  background-color: $neutral;
  color: #35495e;
}
</style>

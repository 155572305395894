<template>
  <div class="bg-white container">
    <h2>Insurance</h2>
    <div>
      <div v-if="canBulkDelete" class="col d-flex justify-content-start my-1">
        <icon-button
          @click="deleteSelectedPolicies"
          icon="trash"
          class="btn btn-outline-danger d-flex align-items-center"
        >
          <span class="ml-2">Delete Selected</span>
        </icon-button>
      </div>
    </div>
    <dx-data-grid
      @initialized="initializeGrid"
      :onCellPrepared="redactLogRocket"
      :dataSource="insurancePolicies"
      :selection="selection"
      @selected-row-keys="handleSelect"
      :row-dragging="rowDrag"
      :columnChooser="columnChooser"
      :toolbar="toolbar"
      :allow-column-resizing="true"
      :allow-column-reordering="true"
      :stateStoring="stateStoring"
      columnResizingMode="widget"
      :scrolling="scrolling"
      :cacheEnabled="false"
    >
      <DxColumn v-for="(column, i) of insuranceGrid" :key="i" v-bind="column" />
      <template v-slot:actions="{ data: { data } }">
        <div class="d-flex align-items-center justify-content-center">
          <icon-button
            v-if="permissions.CaseInsuranceCreateEdit"
            v-tooltip.left.start="'Edit item.'"
            @click="handleEdit(data)"
            class="text-primary p-0 pointer"
            icon="pen-alt"
          />
          <icon-button
            v-if="permissions.CaseInsuranceDelete"
            v-tooltip.right.start="'Delete item.'"
            @click="deleteInsurance(data)"
            class="mx-1 p-0 text-danger pointer"
            icon="trash-alt"
          />
          <icon-button
            v-if="
              labSettings.EnableInsuranceEligibilityCheck && permissions.CaseInsuranceCreateEdit
            "
            v-tooltip.right.start="'Validate Eligibility'"
            @click="validateInsurance(data)"
            class="p-0 text-secondary pointer"
            icon="search"
          />
          <icon-button
            v-if="permissions.CaseInsuranceCreateEdit && data.lastEligibilityRequest != null"
            v-tooltip.right.start="'View Eligibility Checks'"
            @click="viewInsuranceValidations(data)"
            class="ml-1 p-0 text-secondary pointer"
            icon="eye"
          />
        </div>
      </template>
      <template v-slot:extraActions>
        <add-button
          v-if="canAddInsurance && permissions.CaseInsuranceCreateEdit"
          v-shortkey="addShortkey"
          @shortkey="handleAddInsurance"
          @click="handleAddInsurance"
        />
      </template>
    </dx-data-grid>

    <Modal :status="isModalOpen" @close="toggleModal">
      <Insurance
        :caseId="caseId"
        :isAdding="isAdding"
        :isEditing="isEditing"
        :policies="insurancePolicies"
        @submit="handleSubmit"
        v-model="currentInsurance"
        @close="toggleModal"
      />
    </Modal>
    <Modal :status="isInsuranceValidationsOpen" @close="toggleInsuranceValidationsModal">
      <div class="preview-modal">
        <h1>Eligibility Checks</h1>
        <dx-data-grid
          @initialized="intializeEligibilityGrid"
          :dataSource="eligibilities"
          :selection="selection"
          :columns="eligibilityColumns"
          :cacheEnabled="false"
        >
          <DxLoadPanel
            :showIndicator="isEligibilityLoading.enabled"
            :enabled="true"
            :message="isEligibilityLoading.message"
          ></DxLoadPanel>
          <template v-slot:actions="{ data: { data } }">
            <icon-button
              v-if="permissions.CaseInsuranceCreateEdit"
              v-tooltip.left.start="'View Response'"
              @click="viewEligibilityResponse(data)"
              class="text-primary p-0 pointer"
              icon="eye"
            />
          </template>
        </dx-data-grid>
        <modal :status="isPreviewOpen" @close="closePreview">
          <div class="preview-modal">
            <pre v-html="responseForPreview"></pre>
          </div>
        </modal>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Insurance from "@/components/forms/Accession/Insurance.vue";
import InsuranceService from "../services/insurance";
import Modal from "@/components/common/Modal";
import AddButton from "@/components/common/AddButton";
import { DxDataGrid, DxLoadPanel } from "devextreme-vue";
import { from, Subject } from "rxjs";
import { concatMap, switchMap } from "rxjs/operators";
import IconButton from "./common/IconButton.vue";
import { handleErrors } from "@/modules/handleErrors";
import { altKey, formatDatetimeCell, isModalOpen, syntaxHighlight } from "@/modules/helpers";
import { DxColumn } from "devextreme-vue/data-grid";

export default {
  name: "Accession-Insurance-Panel",
  components: { DxDataGrid, Modal, AddButton, Insurance, IconButton, DxLoadPanel, DxColumn },
  provide() {
    return {
      insurances: this.insurancePolicies
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      currentLab: state => state.currentLab,
      insurancePolicies: state => state.accessionStore.insurances,
      states: state => state.dropdowns.states,
      labSettings: state => state.labSettings,
      caseInsuranceState: state => state.grids.caseInsurance
    }),
    ...mapGetters(["permissions"]),

    canAddInsurance() {
      return this.insurancePolicies.length < 3;
    },
    canBulkDelete() {
      return this.selectedInsurances.length > 0;
    },
    insuranceSearch() {
      return InsuranceService.searchStore;
    },
    typeMap() {
      return [1, 2, 3];
    },
    eligibilityColumns() {
      return [
        {
          dataField: "createdOn",
          caption: "Date",
          dataType: "datetime",
          calculateDisplayValue(data) {
            return formatDatetimeCell(data.createdOn);
          }
        },
        {
          dataField: "insuranceCode"
        },

        {
          dataField: "isActive",
          caption: "Status",
          calculateDisplayValue(data) {
            return data.isActive ? "Active" : "Inactive";
          }
        },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions"
        }
      ];
    },
    insuranceGrid() {
      return [
        {
          dataField: "insuranceType",
          caption: "Type",
          lookup: {
            dataSource: [
              {
                displayName: "Primary",
                id: 1
              },
              { displayName: "Secondary", id: 2 },
              { displayName: "Tertiary", id: 3 }
            ],
            valueExpr: "id",
            displayExpr: "displayName"
          },
          sortIndex: 0,
          sortOrder: "asc"
        },
        {
          dataField: "insuranceId",
          caption: "Code",
          lookup: {
            dataSource: this.insuranceSearch,
            displayExpr: "code",
            valueExpr: "id"
          }
        },
        {
          dataField: "insuranceId",
          caption: "Company",
          lookup: {
            dataSource: this.insuranceSearch,
            displayExpr: "description",
            valueExpr: "id"
          }
        },
        {
          dataField: "addressLine1",
          dataType: "Address"
        },
        {
          dataField: "city"
        },
        {
          dataField: "state",
          lookup: {
            dataSource: this.states,
            valueExpr: "id",
            displayExpr: "displayName"
          }
        },
        {
          dataField: "policyNumber"
        },
        {
          dataField: "isActive"
        },
        {
          dataField: "lastEligibilityRequest",
          dataType: "datetime",
          calculateDisplayValue(data) {
            if (data.lastEligibilityRequest) {
              return formatDatetimeCell(data.lastEligibilityRequest);
            }
            return "";
          }
        },

        {
          dataField: "groupNumber"
        },
        {
          dataField: "insuranceRelationship",
          caption: "Relationship",
          lookup: {
            dataSource: [
              { name: "Self", id: 1 },
              { name: "Spouse", id: 2 },
              { name: "Child", id: 3 },
              { name: "Other", id: 4 }
            ],
            displayExpr: "name",
            valueExpr: "id"
          }
        },
        {
          dataField: "guarantor"
        },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions"
        }
      ];
    },
    columnChooser() {
      return {
        enabled: true,
        allowSearch: true,
        mode: "select"
      };
    },
    stateStoring() {
      let customSave = this.customStateSave;
      let customLoad = this.customStateLoad;
      return {
        enabled: true,
        type: "custom",
        customSave,
        customLoad,
        ignoreColumnOptionNames: ["filterValue", "filterValues", "headerFilter", "filterType"]
      };
    }
  },
  created() {
    const { caseId } = this.$route.params;
    if (caseId) {
      this.caseId = caseId;
      this.loadInsurances(caseId);
    }
    if (!this.states?.length) {
      this.$store.dispatch("dropdowns/getStates");
    }
  },
  data() {
    return {
      caseId: null,
      eligibilities: [],
      isInsuranceValidationsOpen: false,
      isEligibilityLoading: { enabled: false, message: "Loading Previous Checks..." },
      currentInsurance: {},
      isModalOpen: false,
      isAdding: false,
      eligibilityGrid: {},
      isPreviewOpen: false,
      isEditing: false,
      selectedInsurances: [],
      grid: {},
      selection: {
        allowSelectAll: true,
        mode: "multiple",
        showCheckBoxesMode: "always"
      },
      responseForPreview: "",
      rowDrag: {
        allowReordering: true,
        dropFeedbackMode: "push",
        onReorder: this.handleDrag
      },
      loadPanel: {},
      toolbar: {
        items: [
          {
            location: "after",
            template: "extraActions"
          },
          {
            name: "columnChooserButton",
            visible: true
          }
        ]
      },
      scrolling: {
        showScrollbar: "always",
        useNative: true
      },
      addShortkey: altKey("a")
    };
  },
  domStreams: ["delete$"],
  subscriptions() {
    this.reorder$ = new Subject();
    return {
      changeOrder$: this.reorder$.pipe(
        switchMap(e => {
          const visibleRows = e.component.getVisibleRows();
          const toIndex = this.insurancePolicies.indexOf(visibleRows[e.toIndex].data);
          const fromIndex = this.insurancePolicies.indexOf(e.itemData);
          const newPolicies = [...this.insurancePolicies];
          newPolicies.splice(fromIndex, 1);
          newPolicies.splice(toIndex, 0, e.itemData);
          newPolicies.forEach((insurance, idx) => {
            insurance.insuranceType = this.typeMap[idx];
          });
          this.$store.commit("accessionStore/setInsurances", newPolicies);
          return from(newPolicies);
        }),
        concatMap(policy => {
          return InsuranceService.updatePolicy(policy);
        })
      )
    };
  },
  watch: {
    isEligibilityLoading: {
      deep: true,
      handler(nv) {
        if (!nv?.enabled && this.eligibilityGrid?.endCustomLoading) {
          this.eligibilityGrid.endCustomLoading();
        }
      }
    }
  },
  methods: {
    closePreview() {
      this.isPreviewOpen = false;
    },
    async viewInsuranceValidations(data) {
      this.isInsuranceValidationsOpen = true;
      this.isPreviewOpen = false;
      this.isEligibilityLoading = { enabled: true, message: "Loading Previous Checks..." };
      const { id } = data;
      try {
        this.eligibilities = await InsuranceService.getEligibilities(id);
      } finally {
        this.isEligibilityLoading.enabled = false;
      }
    },
    viewEligibilityResponse(data) {
      try {
        const jsonString = JSON.stringify(JSON.parse(data.response), null, 2);
        this.responseForPreview = syntaxHighlight(jsonString);
        this.isPreviewOpen = true;
      } catch (error) {
        window.alert("Invalid JSON found");
      }
    },
    async validateInsurance(data) {
      const { id, lastEligibilityRequest } = data;
      if (lastEligibilityRequest != null) {
        const lastRequestDate = formatDatetimeCell(lastEligibilityRequest).toLocaleString("en-US", {
          timeStyle: "short",
          dateStyle: "medium"
        });

        const confirmation = await window.confirm(
          `WARNING: This insurance was checked on ${lastRequestDate}<br> Are you sure you want to continue?`
        );
        if (!confirmation) {
          return;
        }
      }
      this.isEligibilityLoading = { enabled: true, message: "Running Eligibility Request..." };
      this.isInsuranceValidationsOpen = true;
      try {
        await InsuranceService.createEligbility(id);
        await this.viewInsuranceValidations(data);
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isEligibilityLoading.enabled = false;
      }
      this.loadInsurances(this.caseId);
    },
    initializeGrid({ component }) {
      this.grid = component;
      if (!this.insurancePolicies.length && this.permissions.CaseInsuranceCreateEdit) {
        this.isModalOpen = true;
        this.isAdding = true;
      }
    },
    intializeEligibilityGrid({ component }) {
      this.eligibilityGrid = component;
      if (this.isEligibilityLoading?.enabled) {
        component.beginCustomLoading(this.isEligibilityLoading.message);
      }
    },
    async deleteInsurance(data) {
      const confirmation = await window.confirm(
        `WARNING: This action is irreversible.<br> Are you sure you want to delete this insurance policy?`
      );
      if (confirmation && data) {
        const policy = data;
        await InsuranceService.updatePolicy({ ...policy, isDeleted: true });
        const updatedInsurancePolicies = this.insurancePolicies
          .filter(e => e.id !== policy.id)
          .map((insurance, idx) => {
            insurance.insuranceType = this.typeMap[idx];
            return insurance;
          });
        await Promise.all(
          updatedInsurancePolicies.map(policy => InsuranceService.updatePolicy(policy))
        );
        //! UI GETS UPDATED HERE
        this.$store.commit(
          "accessionStore/setInsurances",
          this.insurancePolicies
            .filter(e => e.id !== policy.id)
            .map((insurance, idx) => {
              insurance.insuranceType = this.typeMap[idx];
              return insurance;
            })
        );
      }
    },
    handleSelect(data) {
      this.selectedInsurances = data;
    },
    handleDrag(e) {
      this.reorder$.next(e);
    },
    loadInsurances(caseId) {
      this.$store.dispatch("accessionStore/getInsurances", caseId);
    },
    toggleInsuranceValidationsModal() {
      this.isInsuranceValidationsOpen = !this.isInsuranceValidationsOpen;
    },
    toggleModal() {
      this.currentInsurance = null;
      this.isEditing = !this.isEditing;
      this.isAdding = !this.isAdding;
      return (this.isModalOpen = !this.isModalOpen);
    },
    handleAddInsurance() {
      if (isModalOpen()) {
        return;
      }
      this.isModalOpen = true;
      this.isEditing = false;
      this.isAdding = true;
      this.currentInsurance = {};
    },
    async deleteSelectedPolicies() {
      const confirmation = await window.confirm(
        `WARNING: This action is irreversible.<br> You have ${this.selectedInsurances.length} selected insurance policies. <br> Are you sure you want to delete these?`
      );
      if (confirmation) {
        await Promise.all(
          this.selectedInsurances.map(data => {
            let ins = { ...data, isDeleted: true };
            return InsuranceService.updatePolicy(ins);
          })
        );
        const { caseId } = this.$route.params;
        this.$store.dispatch("accessionStore/getInsurances", caseId);
      }
    },
    setSelected({ selectedRowsData, selectedRowKeys }) {
      this.caseDetails = selectedRowsData[0];
      this.selectedCase = selectedRowsData[0];
      this.selectedRow = selectedRowKeys[0];
    },
    handleEdit(data) {
      if (data != undefined) {
        this.isEditing = true;
        this.currentInsurance = data;
        this.isAdding = false;
        this.isModalOpen = true;
      }
    },
    handleSubmit() {
      this.isEditing = false;
      this.isAdding = false;
      this.isModalOpen = false;
      this.loadInsurances(this.caseId);
    },
    customStateSave(gridState) {
      return this.$store.dispatch("grids/setGridState", {
        name: "caseInsurance",
        gridState,
        columns: this.insuranceGrid
      });
    },
    customStateLoad() {
      return this.caseInsuranceState;
    }
  }
};
</script>

<style lang="scss" scoped>
.grid_menu {
  & > button {
    font-size: 1.2rem;
    font-weight: 500;
    &.active {
      background-color: $primary-light;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
.grid_container {
  background-color: $primary-light;
}
.main_grid {
  background-color: #047ead;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.disabled {
  opacity: 0.7;
}
.preview-modal {
  max-width: 50vw;
}
</style>
